let linkTrendTime = {
  0: [
    "03-15",
    "03-16",
    "03-17",
    "03-18",
    "03-19",
    "03-20",
    "03-21",
    "03-22",
    "03-23",
    "03-24",
    "03-25",
    "03-26",
    "03-27",
    "03-28",
    "03-29",
    "03-30",
    "03-31",
    "04-01",
    "04-02",
    "04-03",
    "04-04",
    "04-05",
    "04-06",
    "04-07",
    "04-08",
    "04-09",
    "04-10",
    "04-11",
    "04-12",
    "04-13",
    "04-14",
  ],
  1: [
    "03-15",
    "03-16",
    "03-17",
    "03-18",
    "03-19",
    "03-20",
    "03-21",
    "03-22",
    "03-23",
    "03-24",
    "03-25",
    "03-26",
    "03-27",
    "03-28",
    "03-29",
    "03-30",
    "03-31",
    "04-01",
    "04-02",
    "04-03",
    "04-04",
    "04-05",
    "04-06",
    "04-07",
    "04-08",
    "04-09",
    "04-10",
    "04-11",
    "04-12",
    "04-13",
    "04-14",
  ],
};

let linkTrendData = {
  0: {
    max: 10428,
    min: -214,
    data: [
      2401, 5775, 4091, 2505, 1468, 3306, 10088, -214, -143, 42, 856, 1892, 3936, 4522, 4903, 10428, 3268, -190, 2980, 1621, 1834, 4793,
      4840, -183, 775, 9874, 2801, -133, 637, -27, -184,
    ],
  },
  1: {
    max: 88773,
    min: 2401,
    data: [
      2401, 8176, 12267, 14772, 16240, 19546, 29634, 29420, 29277, 29319, 30175, 32067, 36003, 40525, 45428, 55856, 59124, 58934, 61914,
      63535, 65369, 70162, 75002, 74819, 75594, 85468, 88269, 88136, 88773, 88746, 88562,
    ],
  },
};

let commentTrendTime = {
  0: [
    "03-15",
    "03-16",
    "03-17",
    "03-18",
    "03-19",
    "03-20",
    "03-21",
    "03-22",
    "03-23",
    "03-24",
    "03-25",
    "03-26",
    "03-27",
    "03-28",
    "03-29",
    "03-30",
    "03-31",
    "04-01",
    "04-02",
    "04-03",
    "04-04",
    "04-05",
    "04-06",
    "04-07",
    "04-08",
    "04-09",
    "04-10",
    "04-11",
    "04-12",
    "04-13",
    "04-14",
  ],
  1: [
    "03-15",
    "03-16",
    "03-17",
    "03-18",
    "03-19",
    "03-20",
    "03-21",
    "03-22",
    "03-23",
    "03-24",
    "03-25",
    "03-26",
    "03-27",
    "03-28",
    "03-29",
    "03-30",
    "03-31",
    "04-01",
    "04-02",
    "04-03",
    "04-04",
    "04-05",
    "04-06",
    "04-07",
    "04-08",
    "04-09",
    "04-10",
    "04-11",
    "04-12",
    "04-13",
    "04-14",
  ],
};

let commentTrendData = {
  0: {
    max: 1028,
    min: -4,
    data: [0, 0, 0, 0, 0, 0, 0, 1028, 0, 0, 0, 5, 0, 0, 1, 43, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -4, 0, 113],
  },
  1: {
    max: 1186,
    min: 0,
    data: [
      0, 0, 0, 0, 0, 0, 0, 1028, 1028, 1028, 1028, 1033, 1033, 1033, 1034, 1077, 1077, 1077, 1077, 1077, 1077, 1077, 1077, 1077, 1077, 1077,
      1077, 1077, 1073, 1073, 1186,
    ],
  },
};

export { linkTrendTime, linkTrendData, commentTrendTime, commentTrendData };
