<template>
    <div class="data">
        <div class="data-header">
            <span class="data-title">数据概况</span>
            <div class="data-content">
                <div class="data-item" v-for="item in severalList">
                    <span class="text">{{item.total}}</span>
                    <span class="g-num">{{item.num}}</span>
                    <div class="added-this-week" v-if="item.total=='涨粉数'|| item.total=='转发总量'">
                        <span class="text">昨日新增</span>
                        <i class="iconfont">&#xe643;</i>
                        <span class="num">{{item.addNum}}</span>
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="data-trend">-->
            <!--<span class="title">点赞趋势</span>-->
            <!--<div class="content">-->
                <!--<div id="likeTrend" :style="{width: '100%', height: '442px'}"></div>-->
            <!--</div>-->
        <!--</div>-->
        <div class="data-trend">
            <span class="title">粉丝趋势</span>
            <div class="content">
                <div class="data-menu">
                    <div class="item" :class="{selected: fanTabIndex === 0}" @click="toggleFanTab(0)">近7天</div>
                    <div class="item" :class="{selected: fanTabIndex === 1}" @click="toggleFanTab(1)">30天</div>
                </div>
                <div id="ecFanTrend" :style="{width: '100%', height: '442px'}"></div>
            </div>
        </div>

        <!-- 点赞趋势 -->
        <div class="data-trend">
            <span class="title">点赞趋势</span>
            <div class="content">
                <div class="data-menu">
                    <div
                        class="item"
                        :class="{ selected: likeTrendIndex === 0 }"
                        @click="likeTrendFunTab(0)"
                    >
                        增量
                    </div>
                    <div
                        class="item"
                        :class="{ selected: likeTrendIndex === 1 }"
                        @click="likeTrendFunTab(1)"
                    >
                        总量
                    </div>
                </div>
                <div id="likeTrend" :style="{ width: '100%', height: '442px' }"></div>
            </div>
        </div>

        <!-- 评论趋势 -->
        <div class="data-trend">
            <span class="title">评论趋势</span>
            <div class="content">
                <div class="data-menu">
                    <div
                        class="item"
                        :class="{ selected: commentIndex === 0 }"
                        @click="commentTrendTab(0)"
                    >
                        增量
                    </div>
                    <div
                        class="item"
                        :class="{ selected: commentIndex === 1 }"
                        @click="commentTrendTab(1)"
                    >
                        总量
                    </div>
                </div>
                <div id="commentTrend" :style="{ width: '100%', height: '442px' }"
                ></div>
            </div>
            </div>
        </div>
</template>

<script>
    import {darenFansTender} from "@/utils/apis";
    import dayjs from "dayjs";
    import { linkTrendTime, linkTrendData, commentTrendTime, commentTrendData } from "./staticEcDataOverviewModuleData.js";

    export default {
        name: "EcDataOverviewModule",
        data(){
            return{
                //数据概况
                mineInfo: {},
                //粉丝趋势近7天/30天切换
                fanTabIndex:0,
                //播主一周内粉丝趋势数据
                weekFansTrend:{},
                //播主一月内粉丝趋势数据
                //点赞趋势近7天/30天切换
                likeTrendIndex: 0,
                // 评论趋势近7天/30天切换
                commentIndex: 0,
                monthFansTrend:{},
                author_id:'',
                severalList: [
                    {
                        total: "点赞总数",
                        num: 0
                    },
                    {
                        total: "评论总数",
                        num: 0
                    },
                    {
                        total: "涨粉数",
                        num: 0,
                        addNum: 0
                    },
                    {
                        total: "转发总量",
                        num: 0,
                        addNum: 0
                    }
                ],
                fansTrend:{},

            }
        },
        // props: {
        //     severalList: Array,
        //     fansTrend: Object
        // },
        mounted() {
            window.onresize = () => {
                this.ecFanTrendFun(this.fansTrend); //粉丝趋势
                this.ecFanTrend.resize()
            };

            let douyinuser_id = this.$route.query.douyinuser_id;

            this.getDate(douyinuser_id);
            this.ecFanTrend = this.$echarts.init(document.getElementById("ecFanTrend"));
            this.fansDatas(douyinuser_id)

            this.likeTrendFun();
            this.commentTrendFun();
        },
        methods: {
            formatUnit(value) {
                if (value < 10000) {
                    return value;
                }
                return (value / 10000).toFixed(2) + "w";
            },
            fansDatas(douyinuser_id, type="week") {
                let end_date = dayjs().subtract(1, "day").format("YYYY-MM-DD")
                let start_date = dayjs().subtract(7, "day").format("YYYY-MM-DD")
                if (type=== "month") {
                    start_date = dayjs().subtract(31, "day").format("YYYY-MM-DD")
                }

                this.getDateFans(douyinuser_id, start_date, end_date);
            },
            // 获取数据概况
            getDate(douyinuser_id) {
                let param = {
                    douyinuser_id: douyinuser_id
                };
                this.$httpStudent.axiosGetBy(this.$api.sellGoodsPeopleDetail, param, res => {
                        if (res.code == 200) {
                            // console.log(res, "aaaaa");
                            //点赞总数
                            this.severalList[0]['num'] = this.formatUnit(res.data.total_favorited);
                            //评论总数
                            this.severalList[1]['num'] = this.formatUnit(res.data.total_comment);
                            //粉丝总数
                            this.severalList[2]['num'] = this.formatUnit(res.data.follower_count);
                            //	比上日增长粉丝变化量
                            this.severalList[2]['addNum'] = this.formatUnit(res.data.follower_incr);
                            //转发总数
                            this.severalList[3]['num'] = this.formatUnit(res.data.total_share);
                            //比上日增长转发变化量
                            this.severalList[3]['addNum'] = this.formatUnit(res.data.share_incr);
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message,
                                duration: 1000
                            });
                        }
                    }
                );
            },
            // 获取数据概况的粉丝趋势数据
            getDateFans(author_id, start_date, end_date) {
                let params = {
                    author_id,
                    start_date,
                    end_date
                }
                this.$httpStudent.axiosGetBy(this.$api.hotPeopleFansData, params, res => {
                        if (res.code == 200) {
                            this.fansTrend = res.data
                            this.ecFanTrendFun(this.fansTrend)
                        } else {
                            this.$message({
                                type: "error",
                                message: res.msg,
                                duration: 1000
                            });
                        }
                    }
                );
            },

            //粉丝趋势近7天/30天切换
            toggleFanTab(index) {
                // console.log(index)
                this.fanTabIndex = index;
                let author_id = this.$route.query.douyinuser_id
                if (index == 0) {
                    this.fansDatas(author_id); //粉丝趋势
                } else {
                    this.fansDatas(author_id, "month"); //粉丝趋势
                }
            },
            // 粉丝趋势
            ecFanTrendFun(ecFanTrendArray) {
                let time = [];
                let addValue = [];
                let totalValue = [];
                ecFanTrendArray.add.forEach((value, key) => {
                    let value_tmp = {...value}
                    time.push(dayjs(value_tmp.time_node * 1000).format("MM-DD"))
                    addValue.push((value_tmp.follower_count/10000).toFixed(2))
                })
                ecFanTrendArray.total.forEach((value, key) => {
                    let value_tmp = {...value}
                    totalValue.push((value_tmp.follower_count/10000).toFixed(2))
                })
                this.ecFanTrend.setOption(
                    {
                        tooltip: {
                            show: true,
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                label: {
                                    backgroundColor: '#6a7985'
                                }
                            },
                            formatter: function (params) {
                                return `${params[0].name}
                                <div style="display: flex; justify-content: flex-start;align-items: center">
                                <i style="background: ${params[0].color};width:10px;height: 10px;display: block;margin-right: 5px;"></i>
                                    ${params[0].seriesName}&nbsp;&nbsp;${params[0].value}w</div>
                                    <div style="display: flex; justify-content: flex-start;align-items: center">
                                <i style="background: ${params[1].color};width:10px;height: 10px;display: block;margin-right: 5px;"></i>
                                    ${params[1].seriesName}&nbsp;&nbsp;${params[1].value}w</div>`;
                            }
                        },
                        title: {
                            x: "center",
                            y: "bottom",
                            textStyle: {
                                //设置主标题风格
                                Color: "#333", //设置主标题字体颜色
                                fontSize: 14,
                                fontStyle: "normal"
                            }
                        },
                        legend: {
                            left: "left",
                            data: ["数据趋势", "数据总计"]
                        },
                        xAxis: {
                            type: "",
                            data: time,
                            //设置网格线颜色
                            boundaryGap: false,
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#999"
                                }
                            }
                        },
                        grid: {
                            left: "3%",
                            right: "4%",
                            bottom: "3%",
                            containLabel: true
                        },
                        yAxis: [
                            {
                                type: 'value',
                                name: '数据趋势',
                                position: "left",
                                axisLabel: {
                                    formatter: '{value} w'
                                }
                            },
                            {
                                type: 'value',
                                name: '数据总计',
                                position: "right",
                                axisLabel: {
                                    formatter: '{value} w'
                                }
                            }
                        ],
                        series: [
                            {
                                name: "数据趋势",
                                data: addValue,
                                type: "line",
                                lineStyle: {
                                    color: "#20C997",
                                    width: 3
                                },
                                areaStyle: {
                                    color: "#d8f6ec"
                                },
                                smooth: true,
                                symbol: "none",
                                yAxisIndex: 1,
                            },
                            {
                                name: "数据总计",
                                data: totalValue,
                                type: "line",
                                lineStyle: {
                                    color: "#FF0001",
                                    width: 3
                                },
                                areaStyle: {
                                    color: "#d8f6ec"
                                },
                                smooth: true,
                                symbol: "none",
                                yAxisIndex: 0,
                            }
                        ]
                    }, true);
            },

                //点赞趋势近7天/30天切换
            likeTrendFunTab(index) {
                this.likeTrendIndex = index;
                if (index == 0) {
                    this.likeTrendFun(this.YeahDiffTrend); //点赞趋势
                } else {
                    this.likeTrendFun(this.YeahTotalTrend); //点赞趋势
                }
            },

            //评论趋势近7天/30天切换
            commentTrendTab(index) {
                this.commentIndex = index;
                if (index == 0) {
                    this.commentTrendFun(this.CommentDiffTrend);
                } else {
                    this.commentTrendFun(this.CommentTotalTrend);
                }
            },

            // 点赞趋势
            likeTrendFun() {
                // 时间和数据
                let time = linkTrendTime[this.likeTrendIndex],
                    data = linkTrendData[this.likeTrendIndex].data;
                let max = linkTrendData[this.likeTrendIndex].max;
                let min = linkTrendData[this.likeTrendIndex].min;
                this.likeTrend = this.$echarts.init(document.getElementById("likeTrend"));
                this.likeTrend.setOption(
                    {
                        tooltip: {
                            trigger: "axis",
                            axisPointer: {
                            type: "cross",
                            label: {
                                backgroundColor: "#6a7985",
                            },
                            },
                        },
                        color: "#584EEE",
                        title: {
                            x: "center",
                            y: "bottom",
                            textStyle: {
                            //设置主标题风格
                            Color: "#333", //设置主标题字体颜色
                            fontSize: 14,
                            fontStyle: "normal",
                            },
                        },
                        xAxis: {
                            type: "",
                            data: time,
                            //设置网格线颜色
                            // boundaryGap: false,
                            // 隐藏刻度线
                            axisTick: {
                                show: false,
                            },
                            axisLabel: {
                                textStyle: {
                                    color: "#999",
                                },
                                // rotate: 40,
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#999",
                                },
                            },
                        },
                        yAxis: {
                            type: "value",
                            name: "点赞量",
                            nameTextStyle: {
                                fontSize: 16,
                                padding: 15,
                            },
                            min: min,
                            max: max,
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    color: "#f1f1f1",
                                },
                            },
                            //隐藏刻度线
                            axisTick: {
                                show: false,
                            },
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: "#999",
                                },
                            },
                            axisLabel: {
                                fontSize: "14",
                                marginTop: "35px",
                                formatter: function (value, index) {
                                    if (value >= 10000) {
                                    return `${value / 10000}W`;
                                    } else {
                                    return value;
                                    }
                                },
                                show: true,
                            },
                        },      
                        series: [
                            {
                                name: "点赞总数",
                                data: data,
                                type: "line",
                                lineStyle: {
                                    color: "#584EEE",
                                    width: 3,
                                },
                                areaStyle: {
                                    //区域填充样式
                                    normal: {
                                    //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                                    color: new this.$echarts.graphic.LinearGradient(
                                        0,
                                        0,
                                        0,
                                        1,
                                        [
                                        {
                                            offset: 0,
                                            color: "rgba(88,78,238,0.9)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(88,78,238,0.1)",
                                        },
                                        ],
                                        false
                                    ),
                                    shadowColor: "rgba(25,163,223, 0.5)", //阴影颜色
                                    shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
                                    },
                                },
                                smooth: true,
                                symbol: "none",
                            },
                        ],
                    },
                    true
                );
            },

            commentTrendFun() {
                // 时间和数据
                let time = commentTrendTime[this.commentIndex],
                    data = commentTrendData[this.commentIndex].data;
                let max = commentTrendData[this.commentIndex].max;
                let min = commentTrendData[this.commentIndex].min;
                this.commentTrend = this.$echarts.init(
                    document.getElementById("commentTrend")
                );
                this.commentTrend.setOption(
                    {
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                        type: "cross",
                        label: {
                            backgroundColor: "#6a7985",
                        },
                        },
                    },
                    color: "#584EEE",
                    title: {
                        x: "center",
                        y: "bottom",
                        textStyle: {
                        //设置主标题风格
                        Color: "#333", //设置主标题字体颜色
                        fontSize: 14,
                        fontStyle: "normal",
                        },
                    },
                    xAxis: {
                        type: "",
                        data: time,
                        //设置网格线颜色
                        // boundaryGap: false,
                        // 隐藏刻度线
                        axisTick: {
                        show: false,
                        },
                        axisLabel: {
                        textStyle: {
                            color: "#999",
                        },
                        // rotate: 40,
                        },
                        axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#999",
                        },
                        },
                    },
                    yAxis: {
                        type: "value",
                        name: "评论数",
                        nameTextStyle: {
                        fontSize: 16,
                        padding: 15,
                        },
                        min: min,
                        max: max,
                        splitLine: {
                        show: true,
                        lineStyle: {
                            color: "#f1f1f1",
                        },
                        },
                        //隐藏刻度线
                        axisTick: {
                        show: false,
                        },
                        axisLine: {
                        show: false,
                        lineStyle: {
                            color: "#999",
                        },
                        },
                        axisLabel: {
                        fontSize: "14",
                        marginTop: "35px",
                        formatter: "{value}",
                        show: true,
                        },
                    },
                    series: [
                        {
                        name: "评论总数",
                        data: data,
                        type: "line",
                        lineStyle: {
                            color: "#584EEE",
                            width: 3,
                        },
                        areaStyle: {
                            //区域填充样式
                            normal: {
                            //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                            color: new this.$echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                {
                                    offset: 0,
                                    color: "rgba(88,78,238,0.9)",
                                },
                                {
                                    offset: 1,
                                    color: "rgba(88,78,238,0.1)",
                                },
                                ],
                                false
                            ),
                            shadowColor: "rgba(25,163,223, 0.5)", //阴影颜色
                            shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
                            },
                        },
                        smooth: true,
                        symbol: "none",
                        },
                    ],
                    },
                    true
                );
            },
        }
}
</script>
<style scoped lang="scss">
    .data{
        padding: 0 25px;
        height: 100%;
        /*overflow: auto;*/
        .data-header{
            .data-title{
                font-size: 14px;
                font-weight: 400;
            }
            .data-content{
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
                line-height: 35px;
                .data-item{
                    display: flex;
                    flex-direction: column;
                    .g-num{
                        font-size: 24px;
                        font-weight: 700;
                        color: #42d7ab;
                    }
                    .added-this-week{
                        & i{
                            font-size: 14px;
                            color: #ffb624;
                        }
                        & .num{
                            font-size: 14px;
                            font-weight: 700;
                            color: #ffb624
                        }

                    }
                }
                .text{
                    color:#656565;
                    font-size: 14px;
                }
            }
        }
        .data-trend{
            margin-top: 20px;
        }
        .data-menu{
            display: flex;
            justify-content: flex-end;
            font-size: 14px;
            font-weight: 400;
            color: #999;
            text-align: right;
            .item {
                display: inline-block;
                padding: 0 10px;
                border-right: 1px solid #999;
                cursor: pointer;
                &:hover{
                    color: #20C997;
                }
                &:last-of-type {
                    border-right: 0;
                    padding: 0 0 0 10px;
                }
            }


            .selected{
                color: #20C997;
            }
        }
    }

</style>